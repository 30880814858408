import React, { Component } from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import ProgressButton from 'react-progress-button';
import { connect } from 'react-redux';
import { post } from '../../../shared/api';
import { createOptions, CardInput, Label, Group } from '../../shared/card.styles';

class UpdateCreditCard extends Component {
  state = {
    error: '',
    buttonState: ''
  };

  onCCChange(event) {
    if (event.error) {
      this.setState({
        error: event.error.message
      });
    } else {
      this.setState({ error: '' });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ buttonState: 'loading' });

    this.props.stripe.createToken({}).then(res => {
      this.onCCChange(res);

      if (res.error) {
        this.setState({ buttonState: '', success: false });
        return;
      }

      post('/api/account/payment', {
        source: res.token.id
      })
        .then(response => {
          this.setState({ buttonState: '', success: true });

          this.props.dispatch({
            type: 'ADD_ACCOUNT_DETAILS',
            payload: { source: response.source }
          });
        })
        .catch(error => {
          this.setState({
            buttonState: '',
            success: false,
            error: 'We were unable to update your credit card. Please contact us.'
          });
        });
    });
  }

  render() {
    return (
      <form onSubmit={e => this.handleSubmit(e)}>
        {this.state.success && (
          <div className="alert alert-success" role="alert">
            Credit card has been successfully updated.
          </div>
        )}
        <Group>
          <h4 className="mb-3">Credit Card Information</h4>

          <p>
            <small>
              In case your credit card is expired, or you want to change it, you can update it here.
              Just enter the new card details below.
            </small>
          </p>

          {this.props.details.source && (
            <div>
              <h6 className="mb-3">Your credit card</h6>
              <p>
                <i className={`fab mr-2 fa-cc-${this.props.details.source.brand.toLowerCase()}`} />
                **** **** **** {this.props.details.source.last4}
              </p>
            </div>
          )}
          <h6 className="mb-3">Update your card</h6>
          <Label>
            <CardInput>
              <CardElement {...createOptions('18px')} onChange={e => this.onCCChange(e)} />
            </CardInput>
          </Label>
        </Group>
        {this.state.error && (
          <div className="alert alert-danger" role="alert">
            {this.state.error}
          </div>
        )}
        <ProgressButton type="submit" controlled={true} state={this.state.buttonState}>
          Update credit card
        </ProgressButton>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    details: state.account.account
  };
};

export default injectStripe(connect(mapStateToProps)(UpdateCreditCard));
