import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, Switch } from 'react-router-dom';
import { fetchAccountDetails } from '../../../actions';
import AccountSubscriptions from './subscriptions';
import AccountOrders from './orders';
import AccountSettings from './settings';

class AccountPage extends Component {
  state = {};

  componentDidMount() {
    this.props.dispatch(fetchAccountDetails());
  }

  render() {
    return (
      <div>
        <h1 className="mb-4">Your Account</h1>
        <ul className="nav nav-tabs mb-2">
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/account">
              Subscriptions
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/account/orders">
              Orders
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/account/settings">
              Account Settings
            </NavLink>
          </li>
        </ul>
        <Switch>
          <Route exact path="/account" component={AccountSubscriptions} />
          <Route path="/account/orders" component={AccountOrders} />
          <Route path="/account/settings" component={AccountSettings} />
        </Switch>
      </div>
    );
  }
}

export default connect()(AccountPage);
