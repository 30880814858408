import React, { Component } from 'react';
import { connect } from 'react-redux';
import Money from 'react-money-component';
import { StyledPrice } from '../styled/Price';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { post } from '../../shared/api';
import { withRouter } from 'react-router-dom';

export const CartItem = styled.div`
  border-top: 1px solid #ddd;
  padding-top: 10px;
`;

export const CartItemTitle = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const TotalPrice = styled.div`
  font-weight: bold;
  font-size: 26px;
  text-align: right;
  margin-bottom: 0;
`;

const DeleteLink = styled.p`
  font-size: 12px;
  cursor: pointer;
`;

class ShoppingCart extends Component {
  state = {};

  getProduct(productId) {
    return this.props.products.find(product => product.id === productId);
  }

  removeFromCart(productId) {
    post('/api/products/remove-cart-item', {
      id: productId
    }).then(response => {
      this.props.dispatch({ type: 'REPLACE_CART', payload: response });
    });
  }

  onCheckout() {
    if (this.props.user.email) {
      this.props.history.push('/cart/checkout');
    } else {
      this.props.history.push('/signup');
    }
  }

  render() {
    const { cart } = this.props;

    if (cart.length === 0) {
      return (
        <div className="white-full">
          <h2 className="mb-4">Your Shopping Basket is empty.</h2>
        </div>
      );
    }

    const totalPrice = cart.reduce((acc, cartItem) => {
      const product = this.getProduct(cartItem.id);

      return acc + product.sku.price * parseInt(cartItem.qty, 10);
    }, 0);

    return (
      <div className="white-full">
        <h2 className="mb-4">Shopping Basket</h2>

        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-6" />
          <div className="col-md-2">Price</div>
          <div className="col-md-2">Quantity</div>
        </div>

        {cart.map(cartItem => {
          const product = this.getProduct(cartItem.id);

          return (
            <CartItem className="row" key={product.id}>
              <div className="col-md-2">
                {product.images && (
                  <img alt={product.name} src={product.images[0]} className="img-fluid mb-4" />
                )}
              </div>
              <div className="col-md-6">
                <CartItemTitle>
                  <Link to={`/product/${product.id}`}>{product.name}</Link>
                </CartItemTitle>
                <DeleteLink onClick={e => this.removeFromCart(product.id)}>
                  Delete from basket
                </DeleteLink>
              </div>
              <div className="col-md-2">
                <StyledPrice>
                  <Money cents={product.sku.price} currency="CAD" />
                </StyledPrice>
              </div>
              <div className="col-md-2">{cartItem.qty}</div>
            </CartItem>
          );
        })}

        <div className="row">
          <div className="col-md-7" />
          <div className="col-md-3">
            <TotalPrice>
              Total:{' '}
              <StyledPrice className="text-right d-inline">
                <Money cents={totalPrice} currency="CAD" />
              </StyledPrice>
            </TotalPrice>
          </div>
          <div className="col-md-2">
            <button onClick={() => this.onCheckout()} className="btn btn-success btn-lg">
              Checkout
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    products: state.products,
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(ShoppingCart));
