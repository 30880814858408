const subscription = (state = {}, action) => {
  switch (action.type) {
    case 'REPLACE_SUBSCRIPTION':
      return action.payload;

    default:
      return state;
  }
}

export default subscription;