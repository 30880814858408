import React, { Component } from 'react';
import './Progress.css';

class Progress extends Component {
  render() {
    const { stage = 0 } = this.props;

    return (
      <div className="row mb-4 text-uppercase">
        <div className="col-md-4">
          <div className="card text-center">
            <div
              className={`card-header ${stage > 0 && 'card-done'} ${stage === 0 && 'card-current'}`}
            >
              {stage > 0 && <i className="fa fa-check" />} Choose filter
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div
              className={`card-header ${stage > 1 && 'card-done'} ${stage === 1 && 'card-current'}`}
            >
              {stage > 1 && <i className="fa fa-check" />} Create account
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div
              className={`card-header ${stage > 2 && 'card-done'} ${stage === 2 && 'card-current'}`}
            >
              {stage > 2 && <i className="fa fa-check" />}Subscribe
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Progress;
