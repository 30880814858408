import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';
import { Query } from 'react-contentful';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function Blog({ match }) {
  const [title, setTitle] = useState('');

  return (
    <div className="blog page-content white-full">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/blog">Blog</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {title}
          </li>
        </ol>
      </nav>
      <Query
        contentType="blogPost"
        query={{
          'fields.slug[in]': `${match.params.slug || ''}`
        }}
      >
        {({ data, error, fetched, loading }) => {
          if (loading || !fetched) {
            return 'Loading...';
          }

          if (error || data.items.length === 0) {
            console.log(error);
            return <Redirect to="/404" />;
          }

          setTitle(data.items[0].fields.title);

          return <Post fields={data.items[0].fields}></Post>;
        }}
      </Query>
    </div>
  );
}

function Post({ fields }) {
  return (
    <div>
      <Helmet>
        {fields.metaDescription && <meta name="description" content={fields.metaDescription} />}
        {fields.metaTags && <meta name="keywords" content={fields.metaTags} />}
      </Helmet>
      <h1>{fields.title}</h1>
      <p>
        <i className="fas fa-calendar-alt"></i>{' '}
        {new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(new Date(fields.publishDate))}
      </p>
      <div>
        <ReactMarkdown source={fields.body} />{' '}
      </div>
    </div>
  );
}

export default Blog;
