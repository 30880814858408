import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressButton from 'react-progress-button';
import { withRouter } from 'react-router-dom';
import { post } from '../../shared/api';
import Money from 'react-money-component';
import { StyledPrice, StyledPriceSmall } from '../styled/Price';
import CheckoutForm from './Form';
import { Elements } from 'react-stripe-elements';
import { fetchAccountDetails } from '../../actions';
import styled from 'styled-components';

const StyledCheckoutItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
`;

class CheckoutPage extends Component {
  state = {
    buttonState: '',
    serverError: ''
  };

  componentDidMount() {
    window.gtag('event', 'checkout', {
      event_category: 'One-time-purchase'
    });
    this.props.dispatch(fetchAccountDetails());
  }

  getProduct(productId) {
    return this.props.products.find(product => product.id === productId);
  }

  handleSubmit(result, address) {
    post('/api/products/order', {
      ...(result && { token: result.token.id }),
      items: this.props.cart.map(cartItem => {
        return {
          sku: cartItem.sku,
          qty: cartItem.qty
        };
      }),
      address
    })
      .then(() => {
        window.gtag('event', 'closed', {
          event_category: 'One-time-purchase'
        });

        this.props.dispatch({ type: 'REPLACE_CART', payload: [] });
        this.props.history.push('/cart/checkout/thanks');
      })
      .catch(() => {
        this.setState({
          buttonState: '',
          serverError:
            'There was an error processing your order. Could you please try again or Contact Us with the link at the bottom of the page.'
        });
      });
  }

  render() {
    const { cart } = this.props;

    const shippingPrice = 1000;

    const totalPrice = cart.reduce((acc, cartItem) => {
      const product = this.getProduct(cartItem.id);

      return acc + product.sku.price * parseInt(cartItem.qty, 10);
    }, shippingPrice);

    return (
      <div className="row white-full">
        <div className="col-md-6">
          <h4 className="mb-4">Review your order</h4>

          {cart.map(cartItem => {
            const product = this.getProduct(cartItem.id);

            return (
              <StyledCheckoutItem className="row" key={cartItem.id}>
                <div className="col-3">
                  {product.images && (
                    <img alt={product.name} src={product.images[0]} className="img-fluid mb-2" />
                  )}
                </div>
                <div className="col-9">
                  <h5>{product.name}</h5>
                  <div className="row">
                    <div className="col-6">
                      <StyledPriceSmall>
                        <Money cents={product.sku.price} currency="CAD" />
                      </StyledPriceSmall>
                    </div>
                    <div className="col-6">
                      <p className="mb-0 font-weight-bold">Quantity: {cartItem.qty}</p>
                    </div>
                  </div>
                </div>
              </StyledCheckoutItem>
            );
          })}

          <p className="mb-0 mt-3 font-weight-bold text-right">Shipping:</p>
          <StyledPriceSmall className="text-right">
            <Money cents={shippingPrice} currency="CAD" />
          </StyledPriceSmall>

          <p className="mb-0 mt-3 font-weight-bold text-right">Order total:</p>
          <StyledPrice className="text-right">
            <Money cents={totalPrice} currency="CAD" />
          </StyledPrice>
        </div>

        <div className="col-md-6">
          <Elements>
            <CheckoutForm
              handleSubmit={(e, address) => this.handleSubmit(e, address)}
              onTokenRequest={() => this.setState({ buttonState: 'loading' })}
              onTokenFail={() => this.setState({ buttonState: '' })}
            >
              <div>
                {this.state.serverError && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.serverError}
                  </div>
                )}
                <ProgressButton type="submit" controlled={true} state={this.state.buttonState}>
                  Buy now
                </ProgressButton>
              </div>
            </CheckoutForm>
          </Elements>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    products: state.products,
    cart: state.cart
  };
};

export default withRouter(connect(mapStateToProps)(CheckoutPage));
