import React from 'react';
import { Redirect } from 'react-router';
import { Query } from 'react-contentful';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function BlogList() {
  return (
    <div className="blog-list page-content white-full">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Blog
          </li>
        </ol>
      </nav>
      <h1>Blog</h1>
      <Query
        contentType="blogPost"
        query={{
          order: '-fields.publishDate'
        }}
      >
        {({ data, error, fetched, loading }) => {
          if (loading || !fetched) {
            return 'Loading...';
          }

          if (error || data.items.length === 0) {
            console.log(error);
            return <Redirect to="/404" />;
          }

          return data.items.map((item, key) => <Post key={key} fields={item.fields}></Post>);
        }}
      </Query>
    </div>
  );
}

function Post({ fields }) {
  return (
    <div>
      <Link to={`/blog/${fields.slug}`}>
        <h3>{fields.title}</h3>
      </Link>
      <p>
        <i className="fas fa-calendar-alt"></i>{' '}
        {new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(new Date(fields.publishDate))}
      </p>
      <div className="d-flex blog-list-preview">
        <img src={fields.thumbnail.fields.file.url}></img>
        <div className="pl-3">
          <ReactMarkdown source={fields.description} />
        </div>
      </div>
      <hr></hr>
    </div>
  );
}

export default BlogList;
