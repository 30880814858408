import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { categories } from '../../constants';

class ProductTitle extends Component {
  render() {
    const defaultTitle = `${this.props.title} - ${
      categories[this.props.category]
    } | Shop Online | Filer Land Canada`;
    let defaultDescription = this.props.description || '';
    defaultDescription = defaultDescription.replace(/\n/g, ' ').replace(/"/g, "'");
    if (defaultDescription && defaultDescription.length > 150) {
      defaultDescription = defaultDescription.substr(0, 150) + '...';
    }

    const {
      'meta-title': metaTitle = defaultTitle,
      'meta-description': metaDescription = defaultDescription,
      'meta-keywords': metaKeywords
    } = this.props.metadata;

    return (
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        {this.props.image && <meta property="og:image" content={this.props.image} />}
      </Helmet>
    );
  }
}

export default ProductTitle;
