import { combineReducers } from 'redux';
import products from './products';
import plans from './plans';
import user from './user';
import cart from './cart';
import subscription from './subscription';
import account from './account';

const filterlandApp = combineReducers({
  products,
  plans,
  user,
  cart,
  subscription,
  account
});

export default filterlandApp;
