import React, { Component } from 'react';
import Money from 'react-money-component';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import ProductTitle from './ProductTitle';
import { StyledPrice } from '../styled/Price';
import { post } from '../../shared/api';
import Subscribe from './Subscribe';
import ImageGallery from 'react-image-gallery';
import Breadcrumbs from '../shared/Breadcrumbs';

class ProductPage extends Component {
  state = {
    qty: 1,
    animate: false,
    activeBuyOption: 'subscribe'
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.product &&
      nextProps.product.metadata &&
      !nextProps.product.metadata.subscription
    ) {
      return { activeBuyOption: 'buy-once' };
    }
    return null;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  buyNow(e) {
    e.preventDefault();

    this.setState({ animate: true });

    post('/api/products/cart', {
      qty: parseInt(this.state.qty, 10),
      type: 'product',
      id: this.props.product.id,
      sku: this.props.product.sku.id
    }).then(response => {
      window.gtag('event', 'add', {
        event_category: 'One-time-purchase'
      });

      this.props.dispatch({ type: 'REPLACE_CART', payload: response });
    });
  }

  continue() {
    this.setState({ animate: false, qty: 1 });
  }

  checkout() {
    if (this.props.user.email) {
      this.props.history.push('/cart/checkout');
    } else {
      this.props.history.push('/signup');
    }
  }

  getMessage() {
    if (parseInt(this.state.qty, 10) === 1) {
      return '1 item added to the cart.';
    } else {
      return `${this.state.qty} items added to the cart.`;
    }
  }

  render() {
    const { product } = this.props;

    if (!product) {
      return <Redirect to="/404" />;
    }

    const { category = '' } = product.metadata;

    return (
      <div className="white-full">
        <ProductTitle
          title={product.name}
          category={category}
          image={product.images[0]}
          description={product.description}
          metadata={product.metadata}
        />

        <Breadcrumbs category={category} name={product.name} />

        <h2>{product.name}</h2>

        <div className="row">
          <div className="col-md-6">
            <ImageGallery
              showPlayButton={false}
              items={product.images.map(imageSrc => {
                return {
                  original: imageSrc,
                  thumbnail: imageSrc
                };
              })}
            />
            <p className="mt-3" style={{ whiteSpace: 'pre-wrap' }}>
              {product.description}
            </p>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Questions?</h5>
                <p>
                  <span className="font-weight-bold">Email us:</span> filterlandcanada@gmail.com
                </p>
                <p>
                  <span className="font-weight-bold">Can't find your filter?</span> Email us a photo
                  of the filter you have and we will find you a replacement
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`card ${this.state.animate ? 'd-none' : ''}`}>
              <div
                className="card-body"
                style={{
                  overflow: 'hidden',
                  height: this.state.activeBuyOption === 'buy-once' ? 'auto' : '95px'
                }}
              >
                <h5 className="card-title">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="buy-once"
                      id="buy-once-option"
                      checked={this.state.activeBuyOption === 'buy-once'}
                      onChange={() => this.setState({ activeBuyOption: 'buy-once' })}
                    />
                    <label className="form-check-label" htmlFor="buy-once-option">
                      One-time purchase
                    </label>
                  </div>
                </h5>

                <StyledPrice>
                  <Money cents={product.sku.price} currency="CAD" />
                </StyledPrice>

                <form className="mt-2">
                  <div className="form-group row">
                    <label className="col-5 col-form-label">Quantity</label>
                    <div className="col-7">
                      <input
                        type="number"
                        max="1000"
                        min="1"
                        required
                        className="form-control"
                        name="qty"
                        id="qty"
                        value={this.state.qty}
                        onChange={e => this.handleInputChange(e)}
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary btn-lg" onClick={e => this.buyNow(e)}>
                    <i className="fas fa-shopping-basket" /> Add to Basket
                  </button>
                </form>
              </div>
            </div>

            <div
              className={`card animated ${this.state.animate ? 'flipInX' : 'd-none'}`}
              style={{ height: '222px' }}
            >
              <div className="card-body">
                <div className="alert alert-success">{this.getMessage()}</div>
                <button className="btn btn-primary btn-lg mr-3" onClick={() => this.checkout()}>
                  Checkout
                </button>
                <button className="btn btn-outline-primary btn-lg" onClick={() => this.continue()}>
                  Continue shopping
                </button>
              </div>
            </div>

            {product.metadata.subscription && (
              <Subscribe
                subscription={product.metadata.subscription}
                productId={product.id}
                activeBuyOption={this.state.activeBuyOption}
                onActiveBuyOptionChange={() => this.setState({ activeBuyOption: 'subscribe' })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    product: state.products.find(product => product.id === ownProps.match.params.id),
    user: state.user
  };
};

export default connect(mapStateToProps)(ProductPage);
