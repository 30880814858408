import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProgressButton from 'react-progress-button';
import { post } from '../../../shared/api';
import Progress from '../../shared/Progress';

class SignupPage extends Component {
  state = {
    buttonState: '',
    error: false,
    checkEmail: false
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({ buttonState: 'loading' });

    post('/api/account/create', this.state).then(
      () => {
        window.gtag('event', 'created', {
          event_category: 'Account'
        });

        this.setState({ error: false, buttonState: '', checkEmail: true });
      },
      () => {
        this.setState({ error: true, buttonState: '' });
      }
    );
  }

  render() {
    return (
      <div>
        <Progress stage={1} />

        <div className="row mt-5">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-header">
                <h3 className="mb-0 my-2">Create your account</h3>
              </div>
              {this.state.checkEmail && (
                <div className="card-body">
                  <p>Please check your email to complete the registration</p>
                </div>
              )}
              {!this.state.checkEmail && (
                <div className="card-body">
                  <form className="form" autoComplete="off" onSubmit={e => this.submit(e)}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        onChange={e => this.handleInputChange(e)}
                        name="email"
                        placeholder="Email address"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        onChange={e => this.handleInputChange(e)}
                        name="password"
                        placeholder="Password"
                        title="At least 6 characters with letters and numbers"
                        minLength="6"
                        required
                      />
                    </div>
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        This email is already registered.
                      </div>
                    )}
                    <div className="form-group">
                      <ProgressButton
                        type="submit"
                        state={this.state.buttonState}
                        controlled={true}
                      >
                        Register
                      </ProgressButton>
                    </div>
                  </form>
                  <p>
                    Already a member? <Link to="login">Sign in now</Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    subscription: state.subscription
  };
};

export default withRouter(connect(mapStateToProps)(SignupPage));
