import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { post } from '../../shared/api';
import logo from '../../assets/logo.png';
import logo2x from '../../assets/logo@2x.png';

import { HeaderStyled, CartIconStyled, MenuListIten, SecondaryMenu } from './styles';

class Header extends Component {
  state = {
    showMenu: false
  };

  logout(e) {
    e.preventDefault();

    post('/api/account/logout');

    this.props.dispatch({ type: 'LOGOUT' });
  }

  render() {
    const { cart } = this.props;

    const totalItems = cart.reduce((acc, cartItem) => {
      return acc + parseInt(cartItem.qty, 10);
    }, 0);

    return (
      <div>
        <SecondaryMenu className="align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md">
                <a href="tel:18008831621">
                  <u>
                    <i className="fas fa-phone mr-2" /> 1-800-883-1621
                  </u>
                </a>
              </div>
              <div className="col-md">
                <ul className="nav nav-pills justify-content-end">
                  <li className="nav-item mr-3">
                    <Link to="/blog" className="nav-link">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item mr-3">
                    <Link to="/faq" className="nav-link">
                      FAQ
                    </Link>
                  </li>
                  {!this.props.isLogged && (
                    <li className="nav-item">
                      <Link to="/signup" className="nav-link">
                        Register
                      </Link>
                    </li>
                  )}
                  {!this.props.isLogged && (
                    <li className="nav-item">
                      <Link to="/login" className="nav-link">
                        Login
                      </Link>
                    </li>
                  )}
                  {this.props.isLogged && (
                    <li className="nav-item">
                      <a href="/" className="nav-link" onClick={e => this.logout(e)}>
                        Logout
                      </a>
                    </li>
                  )}
                  {this.props.isLogged && (
                    <li className="nav-item">
                      <Link to="/account" className="nav-link">
                        My Account
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </SecondaryMenu>
        <HeaderStyled>
          <div className="container">
            <nav className="navbar navbar-light navbar-expand-lg">
              <Link to="/" className="navbar-brand">
                <img src={logo} srcSet={`${logo2x} 2x`} alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => this.setState({ showMenu: !this.state.showMenu })}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className={`collapse justify-content-end navbar-collapse ${
                  this.state.showMenu ? 'show' : ''
                }`}
              >
                <ul className="navbar-nav">
                  <MenuListIten className="nav-item mr-3">
                    <Link to="/category/furnace-filters" className="nav-link">
                      Furnace filters
                    </Link>
                  </MenuListIten>
                  <MenuListIten className="nav-item mr-3">
                    <Link to="/category/humidifier-filters" className="nav-link">
                      Humidifier filters
                    </Link>
                  </MenuListIten>
                  {/* <MenuListIten className="nav-item mr-3">
                    <Link to="/category/vacuum-bags" className="nav-link">
                      Vacuum Bags
                    </Link>
                  </MenuListIten>
                  <MenuListIten className="nav-item mr-3">
                    <Link to="/category/wifi-thermostats" className="nav-link">
                      Wi-Fi Thermostats
                    </Link>
                  </MenuListIten> */}
                  <MenuListIten className="nav-item">
                    <Link to="/cart" className="nav-link">
                      <CartIconStyled className="fas fa-shopping-cart" />
                      Basket {cart.length > 0 && <span>({totalItems})</span>}
                    </Link>
                  </MenuListIten>
                </ul>
              </div>
            </nav>
          </div>
        </HeaderStyled>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLogged: state.user.email ? true : false,
    cart: state.cart
  };
};

export default connect(mapStateToProps)(Header);
