import React, { Component } from 'react';

class ReturnPolicy extends Component {
  state = {};

  render() {
    return (
      <div className="page-content white-full">
        <h2 className="mb-4">Return Policy</h2>

        <p>
          We can't emphasize enough to double check your order before confirming. We accept returns
          of defective or damaged products. If you received a defective item please contact us and
          provide details of the product and it's defect. You can send the item you consider
          defective to the following address: 650 Wilson Ave.
        </p>
      </div>
    );
  }
}

export default ReturnPolicy;
