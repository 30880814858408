import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import placeholder from '../../assets/video_placeholder.png';
import introVideo from '../../assets/intro.mp4';

const Promo = styled.div`
  background-color: white;
  padding: 20px;
  text-align: center;
  margin-bottom: 25px;
  font-family: 'Dosis', sans-serif;

  h1 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .card {
    margin-bottom: 10px;
  }

  .card-intro {
    padding: 16px;
  }
`;

const color = '#4A5899';

class PromoBlock extends Component {
  render() {
    return (
      <Promo>
        <h1>Furnace filter for a few bucks a year</h1>

        <div className="row home-intro">
          <div className="col-md-8">
            <div className="video">
              <video
                controls
                controlsList="nodownload"
                width="100%"
                preload="none"
                poster={placeholder}
              >
                <source src={introVideo} type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="col-md-4 text-uppercase">
            <div
              style={{ cursor: 'pointer' }}
              className="card text-center"
              onClick={() =>
                window.scrollTo({
                  top: this.props.scrollToRef.current.offsetTop,
                  behavior: 'smooth'
                })
              }
            >
              <div className="card-header">1. Choose filter</div>
              <div className="card-body">
                <i className="fas fa-hand-pointer" style={{ fontSize: '40px', color }} />
              </div>
            </div>
            <Link to="/signup">
              <div className="card text-center">
                <div className="card-header">2. Create account</div>
                <div className="card-body">
                  <i className="fas fa-user-circle" style={{ fontSize: '40px', color }} />
                </div>
              </div>
            </Link>
            <div className="card text-center">
              <div className="card-header">3. Subscribe</div>
              <div className="card-body">
                <i className="fas fa-sync" style={{ fontSize: '40px', color }} />
              </div>
            </div>
          </div>
        </div>
      </Promo>
    );
  }
}

export default PromoBlock;
