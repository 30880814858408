import styled from 'styled-components';

export const createOptions = fontSize => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: 'Roboto, Helvetica Neue',
        fontSize: '15px',

        '::placeholder': {
          color: '#CFD7E0'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

export const CardInput = styled.div`
  background: transparent;
  font-weight: 300;
  border: 0;
  color: #31325f;
  outline: none;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  cursor: text;
`;

export const Label = styled.label`
  position: relative;
  color: #8898aa;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
`;

export const Group = styled.div`
  background: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
`;
