import React, { Component } from 'react';
import ProgressButton from 'react-progress-button';
import { post } from '../../shared/api';

class ContactPage extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    success: false,
    buttonState: ''
  };

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ buttonState: 'loading' });

    post('/api/contact', this.state).then(response => {
      this.setState({ success: true, buttonState: '' });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div className="page-content white-full">
        <h2 className="mb-4">Contact us</h2>

        <p>Leave us a message and your contact details so we can get back to you.</p>

        <div className="row">
          <div className="col-sm">
            {this.state.success && (
              <div className="text-center">
                <i
                  className="far fa-check-circle mb-4"
                  style={{ fontSize: '80px', color: 'green' }}
                />
                <p>Thanks! We will get back to you shortly.</p>
              </div>
            )}
            {!this.state.success && (
              <form onSubmit={this.handleSubmit}>
                <div className="form-group row">
                  <label htmlFor="text" className="col-4 col-form-label">
                    Name
                  </label>
                  <div className="col-8">
                    <input
                      id="text"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                      className="form-control"
                      required="required"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label" htmlFor="text1">
                    Email
                  </label>
                  <div className="col-8">
                    <input
                      id="text1"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      className="form-control"
                      required="required"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="text2" className="col-4 col-form-label">
                    Phone number
                  </label>
                  <div className="col-8">
                    <input
                      id="text2"
                      name="phone"
                      type="tel"
                      value={this.state.phone}
                      onChange={this.handleInputChange}
                      className="form-control"
                      required="required"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="textarea" className="col-4 col-form-label">
                    Your message
                  </label>
                  <div className="col-8">
                    <textarea
                      id="textarea"
                      name="message"
                      cols="40"
                      rows="5"
                      value={this.state.message}
                      onChange={this.handleInputChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row justify-content-end">
                  <ProgressButton type="submit" state={this.state.buttonState} controlled={true}>
                    Send message
                  </ProgressButton>
                </div>
              </form>
            )}
          </div>
          <div className="col-sm">
            <p>
              <i className="fas fa-map-marker-alt mr-2" />
              650 Wilson Ave, Toronto On, M3K1E1
            </p>
            <p>
              <i className="fas fa-phone mr-2" />
              <a href="tel:18008831621">1-800-883-1621</a>
            </p>
            <iframe
              title="Filterland location"
              style={{ height: '260px', width: '100%' }}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?q=%20650%20Wilson%20Ave&amp;t=m&amp;z=14&amp;output=embed&amp;iwloc=near"
              aria-label="650 Wilson Ave"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;
