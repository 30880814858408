import styled from 'styled-components';

export const StyledPrice = styled.div`
  color: #83489b;
  font-size: 24px;
`;

export const StyledPriceSmall = styled.div`
  color: #83489b;
  font-size: 18px;
`;
