import React, { Component } from 'react';
import FaqItem from './fag-item';

class FaqPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <h1 className="mb-4">Frequently asked questions</h1>
        <FaqItem title="What is a furnace filter?">
          <p>
            The main purpose of a furnace filter is to protect w blower fan from the dust, hair, and
            other junk that the return duct may be pulling in. Filters can also improve the air
            quality in your house by removing pollen, bacteria, and mould from the air. This is
            especially important for somebody in your family who suffers from allergies.
          </p>
        </FaqItem>

        <FaqItem title="How often do I change the filter?">
          <p>
            Find the filter size you need and choose the frequency as needed. Here are our
            recommendations:
          </p>
          <ul>
            <li>1” filters every 3 months. </li>
            <li>5” filters every 5 months.</li>
          </ul>
        </FaqItem>

        <FaqItem title="What if my furnace filter isn’t in your selection?">
          <p>If you aren’t sure contact us or send us a photo.</p>
        </FaqItem>

        <FaqItem title="What if I don’t know what kind of filter I have.">
          <p>
            Most furnace filters have the size written on their side. They are found inside a
            cabinet attached the furnace. Take it out and find a matching replacement in our store.
            If you have trouble contact us.
          </p>
        </FaqItem>

        <FaqItem title="Do I have to use a filter that is manufactured by the same company as my furnace?">
          <p>No you don’t. As long as it’s the same size brand does not matter.</p>
        </FaqItem>

        <FaqItem title="In which direction should the arrow face?">
          <p>The arrow should point towards the furnace.</p>
        </FaqItem>

        <FaqItem title="If I order a subscription today then what is my billing date?">
          <p>Your bill date will be on the same day on each frequency cycle.</p>
        </FaqItem>

        <FaqItem title="Does it have a warranty?">
          <ul>
            <li>
              If you received a defective item please contact us and provide details of the product
              and it’s defect. You can send the item you consider defective to the following
              address: 650 Wilson Ave.
            </li>
            <li>
              All our filters have a 3 month warranty. If you received a defective item please
              contact us and provide details of the product and it’s defect.
            </li>
          </ul>
        </FaqItem>

        <FaqItem title="Can Filterland install the filter for me?">
          <p>Yes, in most areas of Southern Ontario.</p>
        </FaqItem>

        <FaqItem title="How do I dispose my old filter?">
          <p>Filters should be thrown in the garbage, not recycling.</p>
        </FaqItem>

        <FaqItem title="How soon do you ship?">
          <p>We ship next day at 4pm.</p>
        </FaqItem>
      </div>
    );
  }
}

export default FaqPage;
