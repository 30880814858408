import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressButton from 'react-progress-button';
import AddressForm from '../../shared/Address';
import { post } from '../../../shared/api';
import UpdateCrediCard from './credit-card';
import { Elements } from 'react-stripe-elements';

class AccountSettings extends Component {
  state = {
    buttonState: '',
    success: false,
    name: '',
    address: {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (Object.keys(nextProps.details).length === 0 || Object.keys(prevState.address).length > 0) {
      return null;
    }

    if (!nextProps.details.shipping) {
      return null;
    }

    return {
      name: nextProps.details.shipping.name,
      address: nextProps.details.shipping.address
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ buttonState: 'loading' });

    post('/api/account/details', {
      shipping: {
        name: this.state.name,
        address: this.state.address
      }
    }).then(shipping => {
      this.props.dispatch({
        type: 'ADD_ACCOUNT_DETAILS',
        payload: shipping
      });
      this.setState({ buttonState: '', success: true });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'name') {
      this.setState({ name: value });
    } else {
      this.setState({ address: Object.assign({}, this.state.address, { [name]: value }) });
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Elements>
            <UpdateCrediCard />
          </Elements>
        </div>
        <div className="col-md-6 col-sm-12">
          {this.state.success && (
            <div className="alert alert-success" role="alert">
              Address has been successfully updated.
            </div>
          )}
          <form onSubmit={e => this.handleSubmit(e)}>
            <AddressForm
              name={this.state.name}
              {...this.state.address}
              handleInputChange={e => this.handleInputChange(e)}
            />
            <ProgressButton type="submit" controlled={true} state={this.state.buttonState}>
              Update address
            </ProgressButton>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    details: state.account.account
  };
};

export default connect(mapStateToProps)(AccountSettings);
