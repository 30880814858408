import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Money from 'react-money-component';
import { post } from '../../shared/api';
import { StyledPrice } from '../styled/Price';

class SubscribeComponent extends Component {
  state = {
    qty: 1,
    selected: ''
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.plans.length > 0 && prevState.selected === '') {
      const sortedPlans = nextProps.plans.sort((a, b) => {
        return a.nickname > b.nickname ? -1 : 1;
      });

      return { selected: sortedPlans[0].id };
    }

    return null;
  }

  subscribe() {
    post('/api/subscription/select', {
      qty: this.state.qty,
      plan: this.state.selected,
      ref_product_id: this.props.productId
    }).then(response => {
      window.gtag('event', 'add', {
        event_category: 'Subscription'
      });

      this.props.dispatch({ type: 'REPLACE_SUBSCRIPTION', payload: response });

      if (this.props.user.email) {
        this.props.history.push('/subscribe');
      } else {
        this.props.history.push('/signup');
      }
    });
  }

  onChange(event) {
    this.setState({ selected: event.target.value });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    const { plans } = this.props;
    const { selected } = this.state;
    const sortedPlans = plans.sort((a, b) => {
      return a.nickname > b.nickname ? -1 : 1;
    });

    const selectedPlan = plans.find(plan => plan.id === selected);

    return (
      <div>
        <div className="card mt-3">
          <div
            className="card-body"
            style={{
              overflow: 'hidden',
              height: this.props.activeBuyOption === 'subscribe' ? 'auto' : '95px'
            }}
          >
            <h5 className="card-title">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="buy-option"
                  value="subscribe"
                  id="subscribe-option"
                  checked={this.props.activeBuyOption === 'subscribe'}
                  onChange={() => this.props.onActiveBuyOptionChange()}
                />
                <label className="form-check-label" htmlFor="subscribe-option">
                  Subscribe & Save
                </label>
              </div>
            </h5>

            {selectedPlan && (
              <StyledPrice>
                <Money cents={selectedPlan.amount} currency="CAD" />
              </StyledPrice>
            )}

            <form>
              <div className="form-group row">
                <label className="col-5 col-form-label">Quantity</label>
                <div className="col-7">
                  <input
                    type="number"
                    max="1000"
                    min="1"
                    className="form-control"
                    name="qty"
                    id="qty"
                    required
                    value={this.state.qty}
                    onChange={e => this.handleInputChange(e)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-5 col-form-label">Deliver every</label>
                <div className="col-7">
                  <select
                    name="plan"
                    className="custom-select"
                    value={selected}
                    onChange={e => this.onChange(e)}
                  >
                    {sortedPlans.map(plan => {
                      return (
                        <option value={plan.id} key={plan.id}>
                          {plan.nickname}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </form>

            <button className="btn btn-primary btn-lg mt-2" onClick={() => this.subscribe()}>
              <i className="fas fa-sync-alt" /> Buy & Subscribe
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    plans: state.plans.filter(plan => plan.product === ownProps.subscription) || [],
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(SubscribeComponent));
