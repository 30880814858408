import { get } from '../shared/api';

function addAccountSubscriptions(subscriptions) {
  return {
    type: 'ADD_ACCOUNT_SUBSCRIPTIONS',
    payload: subscriptions
  };
}

export function fetchAccountSubscriptions() {
  return dispatch => {
    dispatch({ type: 'LOAD_ACCOUNT_SUBSCRIPTIONS' });

    get('/api/account-orders/subscriptions').then(subscriptions => {
      dispatch(addAccountSubscriptions(subscriptions));
    });
  };
}

function addAccountOrders(orders) {
  return {
    type: 'ADD_ACCOUNT_ORDERS',
    payload: orders
  };
}

export function fetchAccountOrders() {
  return dispatch => {
    dispatch({ type: 'LOAD_ACCOUNT_ORDERS' });

    get('/api/account-orders/orders').then(orders => {
      dispatch(addAccountOrders(orders));
    });
  };
}

function addAccountDetails(orders) {
  return {
    type: 'ADD_ACCOUNT_DETAILS',
    payload: orders
  };
}

export function fetchAccountDetails() {
  return dispatch => {
    get('/api/account/details').then(orders => {
      dispatch(addAccountDetails(orders));
    });
  };
}
