import React, { Component } from 'react';
import { post } from '../../../shared/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressButton from 'react-progress-button';
import { Link } from 'react-router-dom';

class LoginPage extends Component {
  state = {
    buttonState: '',
    error: false
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: false
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({ buttonState: 'loading' });

    post('/api/account/login', this.state).then(
      result => {
        this.props.dispatch({ type: 'ADD_USER', payload: result });
        const url = new URL(window.location);
        const redirect = url.searchParams.get('redirect');

        if (redirect) {
          this.props.history.push(redirect);
        } else {
          this.props.history.push('/account');
        }
      },
      error => {
        this.setState({ error: true, buttonState: '' });
      }
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h3 className="mb-0 my-2">Sign in</h3>
            </div>
            <div className="card-body">
              <form className="form" autoComplete="off" onSubmit={e => this.submit(e)}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    onChange={e => this.handleInputChange(e)}
                    name="email"
                    placeholder="Email address"
                    autoComplete="email"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    onChange={e => this.handleInputChange(e)}
                    name="password"
                    placeholder="Password"
                    title="At least 6 characters with letters and numbers"
                    autoComplete="current-password"
                    required
                  />
                </div>
                {this.state.error && (
                  <div className="alert alert-danger" role="alert">
                    Password or email is not correct.
                  </div>
                )}
                <div className="form-group">
                  <ProgressButton type="submit" state={this.state.buttonState} controlled={true}>
                    Login
                  </ProgressButton>
                </div>
              </form>
              <p>
                <Link to="reset-password">Forgot your password?</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(LoginPage));
