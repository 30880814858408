import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressButton from 'react-progress-button';
import { withRouter } from 'react-router-dom';
import { post } from '../../shared/api';
import Money from 'react-money-component';
import { StyledPrice, StyledPriceSmall } from '../styled/Price';
import { Link } from 'react-router-dom';
import CheckoutForm from './Form';
import { Elements } from 'react-stripe-elements';
import Progress from '../shared/Progress';
import { fetchAccountDetails } from '../../actions';

class SubscribePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      line1: '',
      line2: '',
      city: '',
      state: 'AB',
      country: 'Canada',
      postal_code: '',

      error: '',
      buttonState: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchAccountDetails());

    window.gtag('event', 'checkout', {
      event_category: 'Subscription'
    });
  }

  handleSubmit(result, address) {
    post('/api/subscription/create', {
      ...(result && { token: result.token.id }),
      plan: this.props.plan.id,
      qty: this.props.subscription.qty,
      address
    })
      .then(result => {
        window.gtag('event', 'closed', {
          event_category: 'Subscription'
        });

        this.props.history.push('/subscribe/thanks');
      })
      .catch(error => {
        this.submitFailed();
      });
  }

  submitFailed() {
    this.setState({
      buttonState: '',
      error:
        'There was an error processing your credit card. Please check your details and try again.'
    });
  }

  render() {
    const { product, plan, subscription } = this.props;

    const shippingPrice = 1000;

    if (!plan) {
      return (
        <div className="row">
          <div className="col-md-6">
            <h4>Please first select the filter you want subsribe to.</h4>
            <Link to="/">Go to homepage</Link>
          </div>
        </div>
      );
    }

    const totalPrice = plan.amount * parseInt(subscription.qty, 10) + shippingPrice;

    return (
      <div>
        <Progress stage={2} />

        <div className="row white-full">
          <div className="col-md-6">
            <h4>Confirm your subscription order to activate it.</h4>
            <p>Your selected filter, delivered to you</p>

            {plan && (
              <div className="row">
                <div className="col-6">
                  {product.images && (
                    <img alt={product.name} src={product.images[0]} className="img-fluid mb-4" />
                  )}
                </div>
                <div className="col-6">
                  <h4>{product.name}</h4>
                  <StyledPrice>
                    <Money cents={plan.amount} currency="CAD" />
                  </StyledPrice>
                  <p className="mb-0 mt-3 font-weight-bold">Quantity:</p>
                  <p>{subscription.qty}</p>
                  <p className="mb-0 mt-3 font-weight-bold">Delivered:</p>
                  <p>{plan.nickname}</p>
                  <p className="mb-0 mt-3 font-weight-bold">Shipping:</p>
                  <StyledPriceSmall>
                    <Money cents={shippingPrice} currency="CAD" />
                  </StyledPriceSmall>

                  <p className="mb-0 mt-3 font-weight-bold">Order total:</p>
                  <StyledPrice>
                    <Money cents={totalPrice} currency="CAD" />
                  </StyledPrice>
                </div>
              </div>
            )}
          </div>

          <div className="col-md-6">
            <Elements>
              <CheckoutForm
                handleSubmit={(result, address) => this.handleSubmit(result, address)}
                onTokenRequest={() => this.setState({ buttonState: 'loading' })}
                onTokenFail={() => this.setState({ buttonState: '' })}
              >
                <div>
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  )}
                  <ProgressButton type="submit" controlled={true} state={this.state.buttonState}>
                    Buy & Subscribe
                  </ProgressButton>
                </div>
              </CheckoutForm>
            </Elements>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const productId = state.subscription.ref_product_id;

  return {
    product: state.products.find(product => product.id === productId) || {},
    plan: state.plans.find(plan => plan.id === state.subscription.plan) || null,
    subscription: state.subscription
  };
};

export default withRouter(connect(mapStateToProps)(SubscribePage));
