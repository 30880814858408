import React, { Component } from 'react';
import { post } from '../../../shared/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressButton from 'react-progress-button';

class ResetPassword extends Component {
  state = {
    buttonState: '',
    error: false,
    success: false
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: false
    });
  }

  componentDidMount() {
    post('/api/account/verify-token', {
      token: this.props.match.params.token
    }).then(
      result => {},
      error => {
        this.setState({ error: true });
      }
    );
  }

  submit(e) {
    e.preventDefault();

    this.setState({ buttonState: 'loading' });

    post('/api/account/new-password', {
      token: this.props.match.params.token,
      password: this.state.password
    }).then(
      result => {
        this.setState({ success: true, buttonState: '' });
      },
      error => {
        this.setState({ error: true, buttonState: '' });
      }
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h3 className="mb-0 my-2">Set a new password</h3>
            </div>
            <div className="card-body">
              {!this.state.success && (
                <form className="form" autoComplete="off" onSubmit={e => this.submit(e)}>
                  <div className="form-group">
                    <label>New password</label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={e => this.handleInputChange(e)}
                      name="password"
                      minLength="6"
                      required
                    />
                  </div>
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      Your token has expired.
                    </div>
                  )}
                  <div className="form-group">
                    <ProgressButton type="submit" state={this.state.buttonState} controlled={true}>
                      Set new password
                    </ProgressButton>
                  </div>
                </form>
              )}
              {this.state.success && (
                <p>
                  Your password has been successfully changed. You can now login with new password.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(ResetPassword));
