import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccountSubscriptions } from '../../../actions';
import Money from 'react-money-component';
import loaderSvg from '../../../assets/loader.svg';

class AccountSubscriptions extends Component {
  state = {};

  componentDidMount() {
    this.props.dispatch(fetchAccountSubscriptions());
  }

  render() {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">Active subscriptions</h5>
          {this.props.subscriptions.loading && (
            <img alt="Loading" className="mx-auto d-block" src={loaderSvg} />
          )}
          {!this.props.subscriptions.loading && (
            <table className="table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Subscribed</th>
                  <th>Delivered</th>
                  <th>Price (Including shipping)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.props.subscriptions.data.length === 0 && (
                  <tr>
                    <td colSpan="6">You don't have any subscription yet. </td>
                  </tr>
                )}
                {this.props.subscriptions.data.length > 0 &&
                  this.props.subscriptions.data.map((subscription, key) => {
                    return (
                      <tr key={key}>
                        <td>{subscription.product}</td>
                        <td>
                          {new Intl.DateTimeFormat('en-CA').format(new Date(subscription.created))}
                        </td>
                        <td>{subscription.interval}</td>
                        <td>
                          <Money cents={subscription.price} currency="CAD" />
                        </td>
                        <td>{subscription.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    subscriptions: state.account.subscriptions
  };
};

export default connect(mapStateToProps)(AccountSubscriptions);
