import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { categories } from '../../constants';

class Breadcrumbs extends Component {
  state = {};
  render() {
    const { category, name } = this.props;

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {name && <Link to={`/category/${category}`}>{categories[category]}</Link>}
            {!name && <span>{categories[category]}</span>}
          </li>
          {name && (
            <li className="breadcrumb-item active" aria-current="page">
              {name}
            </li>
          )}
        </ol>
      </nav>
    );
  }
}

export default Breadcrumbs;
