import fetch from 'isomorphic-fetch';

export const post = (url, body = {}) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  }).then(function(response) {
    if (response.status === 401) {
      window.location = '/login?redirect=' + window.location.pathname;
    } else if (response.status >= 400) {
      throw Error(response.statusText);
    }
    return response.text().then(function(text) {
      let res = {};
      try {
        res = JSON.parse(text);
      } catch (e) {
        console.log('Failed to parse response');
      }
      return res;
    });
  });
};

export const get = url => {
  return fetch(url, {
    credentials: 'include'
  }).then(function(response) {
    if (response.status === 401) {
      window.location = '/login?redirect=' + window.location.pathname;
    } else if (response.status >= 400) {
      console.log('Bad response from server');
    }

    return response.json();
  });
};
