import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import gdmn from '../../assets/gdmn_logo.png';
import aprilaire from '../../assets/aprilaire_logo.jpg';
import gf from '../../assets/general-filters_logo.png';
// import ecobee from '../../assets/ecobee_logo.png';
// import nest from '../../assets/nest_logo.png';

const BrandContainer = styled.div`
  background-color: white;
  padding: 5px;
  margin-bottom: 45px;
  text-align: center;
`;

const Brands = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  a {
    width: 15%;
    height: auto;

    img {
      max-width: 100%;
    }
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
`;

class BrandsComponent extends Component {
  state = {};
  render() {
    return (
      <BrandContainer className="d-none d-md-block">
        <Header>Available brands</Header>

        <Brands>
          <Link to="/category/furnace-filters">
            <img alt="Goodman filters" src={gdmn} />
          </Link>
          <Link to="/category/furnace-filters">
            <img alt="Aprilaire filters" src={aprilaire} />
          </Link>

          <Link to="/category/furnace-filters">
            <img alt="General filters" src={gf} />
          </Link>

          {/* <Link to="/category/wifi-thermostats">
            <img alt="Ecobee" src={ecobee} />
          </Link>

          <Link to="/category/wifi-thermostats">
            <img alt="Nest" src={nest} />
          </Link> */}
        </Brands>
      </BrandContainer>
    );
  }
}

export default BrandsComponent;
