import React, { Component } from 'react';

class ShippingAndReturn extends Component {
  state = {};

  render() {
    return (
      <div className="page-content white-full">
        <div className="row">
          <div className="col-md-2 text-center" />
          <div className="col-md-10">
            <h2 className="mb-4">Shipping & Payment Info</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 text-center">
            <i className="fas fa-shipping-fast" style={{ fontSize: '60px' }} />
          </div>
          <div className="col-md-10">
            <h4>Shipping</h4>

            <p>
              Filterland.ca ships via Canada Post. Orders are shipped out within 24 Hours. Delivered
              within 3-5 business days.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 text-center">
            <i className="fas fa-credit-card" style={{ fontSize: '60px' }} />
          </div>
          <div className="col-md-10">
            <h4>Payment Info</h4>

            <p>
              Filterland.ca is based on convenience so we make sure that there is always a
              convenient method of payment for our customers.
            </p>
            <p>We accept all major credit cards Visa, Master Card, American Express.</p>
            <p>
              We use Stripe to process your payment. It's the same payment provider used in products
              such as Twitter, Pinterest, and Lyft. We do not handle your credit card information
              directly.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ShippingAndReturn;
