import React, { Component } from 'react';
import styled from 'styled-components';

const CardStyled = styled.div``;

const CardHeader = styled.div`
  cursor: pointer;
`;

const CardBody = styled.div`
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
`;

class FaqItem extends Component {
  state = {
    opened: false
  };

  render() {
    return (
      <CardStyled className="card mb-3">
        <CardHeader
          className="card-header"
          onClick={() => this.setState({ opened: !this.state.opened })}
        >
          <h5 className="mb-0">{this.props.title}</h5>
        </CardHeader>
        <CardBody
          style={{ maxHeight: this.state.opened ? '150px' : '0' }}
        >
          <div className="card-body">
            {this.props.children}
          </div>
        </CardBody>
      </CardStyled>
    );
  }
}

export default FaqItem;
