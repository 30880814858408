import React, { Component } from 'react';
import Money from 'react-money-component';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import './Product.css';

class Product extends Component {
  state = {
    imageIndex: 0
  };

  render() {
    const { product } = this.props;

    return (
      <div
        onMouseEnter={() => this.setState({ imageIndex: 1 })}
        onMouseLeave={() => this.setState({ imageIndex: 0 })}
        className="col-md-4 product-list-item text-center"
      >
        <Link to={`/product/${product.id}`}>
          <LazyLoad height={230}>
            <img
              alt={product.name}
              src={product.images[this.state.imageIndex] || product.images[0]}
              className="img-fluid"
            />
          </LazyLoad>
          <h3>{product.name}</h3>
          <div className="price">
            <Money cents={product.sku.price} currency="CAD" />
          </div>
        </Link>
      </div>
    );
  }
}

export default Product;
