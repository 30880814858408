import React, { Component } from 'react';
import * as S from './Address.styles';
import { provinces } from '../../shared/constants';

class AddressForm extends Component {
  render() {
    const {
      line1 = '',
      line2 = '',
      state = '',
      postal_code = '',
      city = 'Canada',
      name = ''
    } = this.props;

    return (
      <S.Group>
        <h4 className="mb-3">Billing/Shipping Address</h4>
        <S.Label>
          <S.LabelText>Full Name</S.LabelText>
          <S.FormInput
            className="form-control"
            autoComplete="name"
            name="name"
            onChange={this.props.handleInputChange}
            value={name}
            required
          />
        </S.Label>
        <S.Label>
          <S.LabelText>Address</S.LabelText>
          <S.FormInput
            className="form-control"
            name="line1"
            autoComplete="address-line1"
            onChange={this.props.handleInputChange}
            value={line1}
            required
          />
        </S.Label>
        <S.Label>
          <S.LabelText>Address (cont.)</S.LabelText>
          <S.FormInput
            className="form-control"
            name="line2"
            autoComplete="address-line2"
            onChange={this.props.handleInputChange}
            value={line2}
          />
        </S.Label>
        <S.Label>
          <S.LabelText>City</S.LabelText>
          <S.FormInput
            className="form-control"
            name="city"
            autoComplete="address-level2"
            onChange={this.props.handleInputChange}
            value={city}
            required
          />
        </S.Label>
        <S.Label>
          <S.LabelText>Province</S.LabelText>
          <select
            style={{ flex: '1' }}
            className="form-control"
            name="state"
            autoComplete="address-level1"
            onChange={this.props.handleInputChange}
            value={state}
            required
          >
            {Object.keys(provinces).map(code => {
              return (
                <option key={code} value={code}>
                  {provinces[code]}
                </option>
              );
            })}
          </select>
        </S.Label>
        <S.Label>
          <S.LabelText>ZIP</S.LabelText>
          <S.FormInput
            className="form-control"
            name="postal_code"
            autoComplete="postal-code"
            onChange={this.props.handleInputChange}
            value={postal_code}
            required
          />
        </S.Label>
        <S.Label>
          <S.LabelText>Country</S.LabelText>
          <S.FormInput
            className="form-control"
            name="country"
            autoComplete="country-name"
            value="Canada"
            disabled
          />
        </S.Label>
      </S.Group>
    );
  }
}

export default AddressForm;
