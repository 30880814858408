import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers';
import { ContentfulClient, ContentfulProvider, ContentfulCache } from 'react-contentful';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

// Allow the passed state to be garbage-collected
if (typeof window !== 'undefined') {
  delete window.__PRELOADED_STATE__;
}

const store = createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

const contentfulClient = new ContentfulClient({
  accessToken: 'SWiYqRgJEGwA4AcPXI-ofJbC0yWad1IQbkKKS6AGeDU',
  space: 'h6lg201rfmkg',
  cache: new ContentfulCache(window.__CONTENTFUL_CACHE__)
});

hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <ContentfulProvider client={contentfulClient}>
        <App />
      </ContentfulProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
