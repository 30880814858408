const user = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return action.payload;

    case 'LOGOUT':
      return {};
      
    default:
      return state;
  }
}

export default user;