import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Helmet } from 'react-helmet';
import { Switch, Route, withRouter } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Products from './components/Products';
import CategoryPage from './components/Products/Category';
import ProductPage from './components/ProductPage';
import SignupPage from './components/account/Signup/Signup';
import { LoginPage, ResetPasswordPage, ResetPage, AccountPage } from './components/account';
import {
  SubscribePage,
  CheckoutPage,
  CheckoutThanks,
  SubscribeThanks
} from './components/Checkout';
import FaqPage from './components/Faq';
import ReturnPolicy from './components/pages/return-policy';
import ShippingAndReturn from './components/pages/shipping';
import OurStory from './components/pages/our-story';
import ContactPage from './components/pages/contact';
import NotFound from './components/pages/not-found';
import ShoppingCart from './components/Checkout/Cart';
import Blog from './components/Blog/Blog';
import BlogList from './components/Blog/Blog-List';

import '../node_modules/react-progress-button/react-progress-button.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './App.css';

class App extends Component {
  state = {
    stripe: null
  };

  componentDidMount() {
    this.setState({
      stripe: window.Stripe(window.stripeKey)
    });

    this.props.history.listen((location, action) => {
      window.gtag('config', process.env.RAZZLE_GTAG, { page_path: location.pathname });

      window.scrollTo(0, 0);
    });
  }

  render() {
    if (process.env.MAINTENANCE === 'true') {
      return (
        <h1 className="text-center mt-5">
          We are updating our website with brand new version. Please come back soon.
        </h1>
      );
    }

    return (
      <StripeProvider stripe={this.state.stripe}>
        <div>
          <Helmet>
            <title>Buy Furnace Filters Online in Canada Now</title>
            <meta
              name="description"
              content="Buy quality furnace filters online now from our store - Filterland.ca. Buy one time or
              subscribe us to save on your order. Place your order in Canada now."
            />
            <meta name="keywords" content="Furnace Filters,Furnace Filters Online" />
          </Helmet>
          <Header />
          <div className="container app">
            <Switch>
              <Route exact path="/" component={Products} />
              <Route path="/category/:id" component={CategoryPage} />
              <Route path="/product/:id" component={ProductPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/account" component={AccountPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route path="/reset/:token" component={ResetPage} />
              <Route exact path="/cart" component={ShoppingCart} />
              <Route exact path="/cart/checkout" component={CheckoutPage} />
              <Route path="/cart/checkout/thanks" component={CheckoutThanks} />
              <Route exact path="/subscribe" component={SubscribePage} />
              <Route path="/subscribe/thanks" component={SubscribeThanks} />
              <Route path="/faq" component={FaqPage} />
              <Route path="/page/policy" component={ReturnPolicy} />
              <Route path="/page/shipping" component={ShippingAndReturn} />
              <Route path="/page/our-story" component={OurStory} />
              <Route path="/page/contact-us" component={ContactPage} />
              <Route exact path="/blog" component={BlogList} />
              <Route path="/blog/:slug" component={Blog} />
              <Route
                render={({ staticContext }) => {
                  if (staticContext) {
                    staticContext.status = 404;
                  }
                  return <NotFound />;
                }}
              />
            </Switch>
          </div>
          <Footer />
        </div>
      </StripeProvider>
    );
  }
}

export default withRouter(App);
