import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccountOrders } from '../../../actions';
import Money from 'react-money-component';
import loaderSvg from '../../../assets/loader.svg';

class AccountOrders extends Component {
  state = {};

  componentDidMount() {
    this.props.dispatch(fetchAccountOrders());
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Your orders</h5>
          {this.props.orders.loading && (
            <img alt="Loading" className="mx-auto d-block" src={loaderSvg} />
          )}
          {!this.props.orders.loading && (
            <table className="table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Total</th>
                  <th>Order placed</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.props.orders.data.length === 0 && (
                  <tr>
                    <td colSpan="6">You didn't place any order yet. </td>
                  </tr>
                )}
                {this.props.orders.data.length > 0 &&
                  this.props.orders.data.map((order, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {order.items.map((item, index) => {
                            return (
                              <p key={index} className="mb-0">
                                {item.description}
                              </p>
                            );
                          })}
                        </td>
                        <td>
                          <Money cents={order.price} currency="CAD" />
                        </td>
                        <td>{new Intl.DateTimeFormat('en-CA').format(new Date(order.created))}</td>
                        <td>{order.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.account.orders
  };
};

export default connect(mapStateToProps)(AccountOrders);
