import styled from 'styled-components';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  min-height: 81px;
  border-bottom: 1px solid #83489b;

  .container {
    position: relative;
  }
`;

export const SecondaryMenu = styled.div`
  background-color: #83489b;
  a {
    color: white;
  }
`;

export const CartIconStyled = styled.i`
  font-size: 25px;
  margin-right: 10px;
  vertical-align: middle;
`;

export const MenuListIten = styled.li`
  text-transform: uppercase;
  font-weight: bold;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #83489b;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover&:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
`;
