import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import AddressForm from '../shared/Address';
import { createOptions, CardInput, Label, Group } from '../shared/card.styles';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      line1: '',
      line2: '',
      city: '',
      state: 'AB',
      country: 'Canada',
      postal_code: '',

      error: '',
      cc_choise: 'new'
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onCCChange = this.onCCChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (Object.keys(nextProps.account).length === 0 || prevState.name !== '') {
      return null;
    }

    if (!nextProps.account.shipping) {
      return null;
    }

    return {
      name: nextProps.account.shipping.name,
      ...nextProps.account.shipping.address,
      ...(nextProps.account.source ? { cc_choise: 'existing' } : { cc_choise: 'new' })
    };
  }

  onCCChange(event) {
    if (event.error) {
      this.setState({
        error: event.error.message
      });
    } else {
      this.setState({ error: '' });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.onTokenRequest();

    if (this.state.cc_choise === 'new') {
      this.props.stripe.createToken(this.getAddress()).then(res => {
        this.onCCChange(res);

        if (res.error) {
          this.props.onTokenFail();
          return;
        }

        this.props.handleSubmit(res, this.getAddress());
      });
    } else {
      this.props.handleSubmit(null, this.getAddress());
    }
  }

  getAddress() {
    return {
      name: this.state.name,
      line1: this.state.line1,
      line2: this.state.line2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postal_code: this.state.postal_code
    };
  }

  render() {
    return (
      <form onSubmit={e => this.handleSubmit(e)} className="checkout-form">
        <div>
          <Group>
            <h4 className="mb-3">Credit Card Information</h4>
            {this.props.account.source && (
              <>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="cc-choise"
                    id="use-existing-cc"
                    checked={this.state.cc_choise === 'existing'}
                    onChange={() => this.setState({ cc_choise: 'existing' })}
                  />
                  <label className="form-check-label" htmlFor="use-existing-cc">
                    Use existing credit card
                  </label>
                </div>

                {this.state.cc_choise === 'existing' && (
                  <div>
                    <p>
                      <i
                        className={`fab mr-2 fa-cc-${this.props.account.source.brand.toLowerCase()}`}
                      />
                      **** **** **** {this.props.account.source.last4}
                    </p>
                  </div>
                )}
              </>
            )}
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="cc-choise"
                id="use-new-cc"
                checked={this.state.cc_choise === 'new'}
                onChange={() => this.setState({ cc_choise: 'new' })}
              />
              <label className="form-check-label" htmlFor="use-new-cc">
                Enter credit card
              </label>
            </div>
            {this.state.cc_choise === 'new' && (
              <Label>
                <CardInput>
                  <CardElement {...createOptions('18px')} onChange={this.onCCChange} />
                </CardInput>
              </Label>
            )}
          </Group>
          <AddressForm {...this.state} handleInputChange={e => this.handleInputChange(e)} />
          {this.state.error && (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          )}
        </div>
        {this.props.children}
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    account: state.account ? state.account.account : null
  };
};

export default injectStripe(withRouter(connect(mapStateToProps)(CheckoutForm)));
