const defaultState = {
  account: {},
  subscriptions: {
    loading: true,
    data: []
  },
  orders: {
    loading: true,
    data: []
  }
};

const account = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_ACCOUNT_SUBSCRIPTIONS': {
      return {
        ...state,
        subscriptions: {
          loading: true,
          data: []
        }
      };
    }
    case 'ADD_ACCOUNT_SUBSCRIPTIONS':
      return {
        ...state,
        subscriptions: {
          loading: false,
          data: action.payload
        }
      };

    case 'LOAD_ACCOUNT_ORDERS': {
      return {
        ...state,
        orders: {
          loading: true,
          data: []
        }
      };
    }
    case 'ADD_ACCOUNT_ORDERS':
      return {
        ...state,
        orders: {
          loading: false,
          data: action.payload
        }
      };

    case 'ADD_ACCOUNT_DETAILS':
      return {
        ...state,
        account: { ...state.account, ...action.payload }
      };

    default:
      return state;
  }
};

export default account;
