import React, { Component } from 'react';
import { connect } from 'react-redux';
import Product from './Product';
import PromoBlock from './Promo';
import BrandsComponent from './Brands';
import styled from 'styled-components';
import RecentBlog from './RecentBlog';

const ProductsContainer = styled.div`
  h2 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

class Products extends Component {
  constructor(props) {
    super(props);

    this.productsRef = React.createRef();
  }

  render() {
    const products = this.props.products.sort((a, b) => {
      if (a.metadata.category === b.metadata.category) {
        return a.name > b.name ? 1 : -1;
      }

      return a.metadata.category > b.metadata.category ? 1 : -1;
    });

    return (
      <div>
        <PromoBlock scrollToRef={this.productsRef} />
        <BrandsComponent />
        <RecentBlog></RecentBlog>
        <ProductsContainer>
          <h2 ref={this.productsRef}>Our products</h2>
          <div className="row">
            {products.map(product => {
              return <Product product={product} key={product.id} />;
            })}
          </div>
        </ProductsContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products
  };
};

export default connect(mapStateToProps)(Products);
