import React, { Component } from 'react';
import { post } from '../../../shared/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressButton from 'react-progress-button';

class ResetPassword extends Component {
  state = {
    buttonState: '',
    error: false,
    success: false
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      error: false
    });
  }

  submit(e) {
    e.preventDefault();

    this.setState({ buttonState: 'loading' });

    post('/api/account/reset', this.state).then(
      result => {
        this.setState({ success: true, buttonState: '' });
      },
      error => {
        this.setState({ error: true, buttonState: '' });
      }
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h3 className="mb-0 my-2">Reset your password</h3>
            </div>
            <div className="card-body">
              {!this.state.success && (
                <form className="form" autoComplete="off" onSubmit={e => this.submit(e)}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={e => this.handleInputChange(e)}
                      name="email"
                      placeholder="email@gmail.com"
                      autoComplete="email"
                      required
                    />
                  </div>
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      We could not match your email address.
                    </div>
                  )}
                  <div className="form-group">
                    <ProgressButton type="submit" state={this.state.buttonState} controlled={true}>
                      Reset
                    </ProgressButton>
                  </div>
                </form>
              )}
              {this.state.success && (
                <p>
                  An email has been successfully send to {this.state.email} with further
                  instructions.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(ResetPassword));
