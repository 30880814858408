export const categoriesMetaTags = {
  'furnace-filters': {
    title: 'Buy Furnace Filters from Top Brands in Canada',
    description:
      'Buy furnace filters & accessories from brands like Reserve pro, Honeywell, The M One, & The M Zero. Buy Furnace filters from anywhere in Canada and start saving now!',
    keywords: 'Buy Furnace Filters Canada'
  },
  'humidifier-filters': {
    title: 'Shop Humidifier Filters & Pads Online in Canada',
    description:
      'Shop vapor pads, furnace filters, humidifier pads & humidifier filters online in Canada from Filter Land. Buy one time or subscribe us to save on your order.',
    keywords: 'Humidifier filters Online, Humidifier filters Canada'
  }
};
