import React, { Component } from 'react';
import story from '../../assets/our-story.jpg';

class OurStory extends Component {
  state = {};

  render() {
    return (
      <div className="page-content white-full">
        <h2 className="mb-4">Our Story</h2>

        <p>
          Filterland is a collaboration between two good Toronto friends who met on the first day of
          trade school in George Brown College. Today, Orest Omeliukh is the owner of{' '}
          <a href="https://brhvac.ca/">Blackrock HVAC Ltd</a>, a small company specializing in
          heating and cooling systems. Luca Zampini is the owner is{' '}
          <a href="https://vacman.ca/" target="_blank">
            VACMAN.CA
          </a>
          , specializing in central vacuum systems, making sure your home is clean. Both of these
          twenty-something years olds come from a strong family business background - learning
          trades from their fathers - sharing very similar business values.
        </p>

        <p>
          Both being in the service industry from a young age, the two have introduced FilterLand.ca
          to the Southern Ontario market (with plans for expansion). They take pride in making sure
          homeowners and their children have the cleanest and healthiest air inside their homes, all
          at a touch of a button. Knowing the most common filter sizes in Ontario, Filterland is
          supposed to make your life easier, because there are better things to do then remembering
          about your furnace filter (and paying for repairs!)
        </p>
        <img src={story} className="mx-auto img-fluid d-block" alt="Our story" />
        <h3 className="mt-5">Mission statement</h3>
        <p>
          FILTERLAND.CA is the heating isle of a big box store. We provide homeowners with the
          correct filters, products, and technical knowledge that we find big box employees lack.
          Filter subscription customers receive our essential reminders to change filters on time.
          This will save you furnace repair money and keep the air inside clean. All at the right
          price, delivered to your door, installed by a pro. Our friendly and approachable staff
          will educate, inspire, and problem solve all with a valued personal touch.
        </p>
      </div>
    );
  }
}

export default OurStory;
