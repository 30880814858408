import React, { Component } from 'react';

class NotFound extends Component {
  state = {};

  render() {
    return (
      <div className="page-content white-full">
        <h2 className="mb-4">404 - Not found</h2>

        <p>The page you requested was not found.</p>
      </div>
    );
  }
}

export default NotFound;
