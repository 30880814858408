import React, { Component } from 'react';

class CheckoutThanks extends Component {
  render() {
    return (
      <div className="white-full text-center pt-5">
        <i className="far fa-check-circle mb-4" style={{ fontSize: '80px', color: 'green' }} />
        <h4>Thanks! We received you order!</h4>
        <p>
          Your order will be shipped out within 24 Hours and delivered within 3-5 business days.
        </p>
      </div>
    );
  }
}

export default CheckoutThanks;
