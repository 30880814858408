import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Product from './Product';
import styled from 'styled-components';
import Breadcrumbs from '../shared/Breadcrumbs';
import { categories } from '../../constants';
import { categoriesMetaTags } from '../../shared/keywords';

const ProductsContainer = styled.div`
  h2 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

class CategoryPage extends Component {
  render() {
    const metaTags = categoriesMetaTags[this.props.match.params.id] || {};

    const title =
      metaTags.title ||
      `${categories[this.props.match.params.id]} | Shop Online | Filer Land Canada`;

    return (
      <div>
        <Helmet>
          <title>{title}</title>
          {metaTags.description && <meta name="description" content={metaTags.description} />}
          {metaTags.description && <meta name="keywords" content={metaTags.keywords} />}
        </Helmet>
        <Breadcrumbs category={this.props.match.params.id} />

        <ProductsContainer>
          <h2>{categories[this.props.match.params.id]}</h2>
          <div className="row">
            {this.props.products.map(product => {
              return <Product product={product} key={product.id} />;
            })}
          </div>
        </ProductsContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products.filter(
      product => product.metadata.category === ownProps.match.params.id
    )
  };
};

export default connect(mapStateToProps)(CategoryPage);
