import React from 'react';
import { Query } from 'react-contentful';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Header = styled.h2`
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
`;

const BlogItemBlock = styled.div`
  margin-bottom: 45px;

  a {
    position: relative;
    display: block;
  }
  img {
    max-width: 100%;
  }

  p {
    font-size: 18px;
    text-align: center;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    margin: 0;
    padding: 11px;
    color: white;
  }
`;

export default function RecentBlog() {
  return (
    <div>
      <Header>Latest from our blog</Header>
      <div className="row">
        <Query contentType="blogPost" query={{ limit: 3, order: '-fields.publishDate' }}>
          {({ data, error, fetched, loading }) => {
            if (loading || !fetched) {
              return 'Loading...';
            }

            if (!data) {
              console.log('error fetching', error);
              return null;
            }

            return data.items.map((item, key) => (
              <BlogItem key={key} fields={item.fields}></BlogItem>
            ));
          }}
        </Query>
      </div>
    </div>
  );
}

function BlogItem({ fields }) {
  return (
    <BlogItemBlock className="col-md-4">
      <Link to={`/blog/${fields.slug}`}>
        <img src={fields.thumbnail.fields.file.url}></img>
        <p>{fields.title}</p>
      </Link>
    </BlogItemBlock>
  );
}
