import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import payments from '../../assets/payment.png';

const FooterStyled = styled.div`
  background-color: #fff;
  min-height: 50px;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #83489b;
  flex-direction: column;

  img {
    width: 300px;
    margin-bottom: 10px;
  }
`;

const List = styled.ul`
  margin-bottom: 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 10px 12px;
  vertical-align: middle;
  color: #fff;

  a {
    color: #fff;
  }
`;

class Footer extends Component {
  state = {};
  render() {
    return (
      <FooterStyled>
        <List>
          <ListItem>©2015–2020 Filter Land</ListItem>
          <ListItem>
            <Link to="/page/our-story">Our story</Link>
          </ListItem>
          <ListItem>
            <Link to="/page/shipping">Shipping & Payment Info</Link>
          </ListItem>
          <ListItem>
            <Link to="/page/policy">Return Policy</Link>
          </ListItem>
          <ListItem>
            <Link to="/page/contact-us">Contact us</Link>
          </ListItem>
        </List>
        <img src={payments} alt="Secure payments. Powered by Stripe."></img>
      </FooterStyled>
    );
  }
}

export default Footer;
